/* --------------------------------------------------------------------------------------
   common.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/
import { commonService } from './services/common.service'
import { apiClientService } from './services/apiClient.service'
import { printService } from './services/print.service'
import { persistService } from './services/persist.service'
import { sessionDataService } from './services/sessionData.service'
import { jobStatusService } from './services/jobStatus.service'
import { deviceInfoService } from './services/deviceInfo.service'
import { authService } from './services/auth.service'
import { workflowScanningService } from './services/workflowScanning.service'
import { snmpService } from './services/snmp.service'
import { errorService } from './services/error.service'
import { shellDataBagService } from './services/shellDataBag.service'
import { webServiceStatusService } from './services/webServicesStatus.service'
import { templateService } from './services/template.service'
import { sessionService } from './services/session.service'


import { actionBarComponent } from './components/actionBar.component'
import { activityComponent } from './components/activity.component'
import { calendarPopupComponent } from './components/calendarPopup.component'
import { errorMessageComponent } from './components/errorMessage.component'
import { warningMessageComponent } from './components/warningMessage.component'
import { buildVersionComponent } from './components/buildVersion.component'
import { eCommerceBalanceComponent } from './components/eCommerceBalance.component'
import { zoomPopupComponent } from './components/zoomPopup.component';

import { switchSelectorComponent } from './components/fieldSelectors/switchSelector.component'
import { listSelectorComponent } from './components/fieldSelectors/listSelector.component'
import { listSelectorPopupComponent } from './components/fieldSelectors/listSelectorPopup.component'
import { listSelectorPopoverComponent } from './components/fieldSelectors/listSelectorPopover.component'

import { onTap } from './directives/onTap.directive'
import { xdaCalendar } from './directives/xdaCalendar.directive'

import './styles/pignose.calendar.css';
import './styles/xdaCalendar.less';

export const appCommonModule = angular.module('app.common', [])
    .service('commonService', commonService)
    .service('apiClientService', apiClientService)
    .service('printService', printService)
    .service('persistService', persistService)
    .service('sessionDataService', sessionDataService)
    .service('jobStatusService', jobStatusService)
    .service('deviceInfoService', deviceInfoService)
    .service('authService', authService)
    .service('workflowScanningService', workflowScanningService)
    .service('snmpService', snmpService)
    .service('errorService', errorService)
    .service('shellDataBagService', shellDataBagService)
    .service('webServiceStatusService', webServiceStatusService)
    .service('templateService', templateService)
    .service('sessionService', sessionService)    
    .component('actionBar', actionBarComponent)
    .component('activity', activityComponent)
    .component('calendarPopup', calendarPopupComponent)
    .component('errorMessage', errorMessageComponent)
    .component('warningMessage', warningMessageComponent)
    .component('buildVersion', buildVersionComponent)
    .component('switchSelector', switchSelectorComponent)
    .component('listSelector', listSelectorComponent)
    .component('listSelectorPopup', listSelectorPopupComponent)
    .component('listSelectorPopover', listSelectorPopoverComponent)
    .component('eCommerceBalance', eCommerceBalanceComponent)
    .component('zoomPopup', zoomPopupComponent)
    .directive('onTap', onTap.directiveFactory)
    .directive('xdaCalendar', xdaCalendar.directiveFactory)
