/* --------------------------------------------------------------------------------------
  workflow.service.js
  Copyright © 2021 Xerox Corporation. All Rights Reserved.

  Copyright protection claimed includes all forms and matters of copyrightable material
  and information now allowed by statutory or judicial law or hereinafter granted,
  including without limitation, material generated from the software programs which
  are displayed on the screen such as icons, screen display looks, etc.
  -------------------------------------------------------------------------------------
*/

export class workflowService {
  /* @ngInject*/
  constructor(gcpService, $translate) {
    this.gcpService = gcpService;
    this.$translate = $translate;
    this.workflows = [];
    this.date = undefined;
    this.dateBackup = undefined;
    this.workFlowDetails = undefined;
    this.display = false;
  }

  async getWorkflows() {
    this.workflows = await this.gcpService.getWorkflows();
    return this.workflows;
  }

  async getWorkFlowDetails() {
    try {
      if (!this.workFlowDetails || this.workFlowDetails.length == 0 || this.workFlowDetails[0].workflowID != this.selectedWorkflow.workflowId) {
        this.workFlowDetails = await this.gcpService.getWorkFlowMetaData(this.selectedWorkflow.workflowId);
      }
      return this.workFlowDetails;      
    } catch (error) {
      if (error.status == 404) {
        this.workFlowDetails = {
          result: [],
        };
        return this.workFlowDetails;
      } else {
        throw error;
      }
    }
  }

  async setDefaultLanguage() {
    if (!this.languages) this.languages = this.languagesService.getLanguages();
    this.language = this.languages[4];
    this.searchable = true;
  }

  hasData() {
    return this.workFlowDetails && this.workFlowDetails.length > 0;
  }
};
