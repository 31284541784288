/* --------------------------------------------------------------------------------------
landing.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export const landingComponent = {
  template: `
      <activity display="vm.loading" label="{{ 'LOADING_GCP' | translate }}" on-close="vm.onLoadingClose()"></activity>
      <div xw-alert display="vm.maintenance" class="maintenance-indicator" close-button on-close="vm.onMaintenanceClose()">
        <div class="center-activity">
          <div xw-activity widget-size="xrx-large"></div>
        </div>
        <p class="white-text"><strong>{{ 'INSTALLING_APP_UPDATES' | translate }}</strong></p>
        <div class="error-description">
          <p class="activity-subtitle">{{ 'THIS_MAY_TAKE_SEVERAL_MINUTES' | translate }}</p>
          <p class="activity-subtitle">{{ 'CLOSE_ALERT_CONTINUE_WORKING' | translate }}</p>
        </div>
      </div>
      <div class="hidden-maintenance-override" ng-show="vm.maintenance" ng-click="vm.maintenanceOff()"></div>
      <error-message
        ng-if="vm.errorType"
        error-type="vm.errorType"
        on-close="vm.onErrorClose()"
      ></error-message>
  `,
  controllerAs: "vm",
  controller: class landingController {
    /* @ngInject */
    constructor(
      apiClientService,
      shellDataBagService,
      gcpService,
      commonService,
      errorService,
      deviceInfoService,
      workflowService,
      webServiceStatusService,
      $state,
    ) {
      this.apiClientService = apiClientService;
      this.shellDataBagService = shellDataBagService;
      this.gcpService = gcpService;
      this.commonService = commonService;
      this.deviceInfoService = deviceInfoService;
      this.errorService = errorService;
      this.workflowService = workflowService;
      this.webServiceStatusService = webServiceStatusService;
      this.$state = $state;
    }
    async $onInit() {
      this.loading = true;

      const eipStatus = await this.webServiceStatusService.getStatus();

      if (eipStatus.some((s) => !s)) {
        const keys = [];
        if (!eipStatus[0]) {
          keys.push("DEVICE_CONFIGURATION");
        }
        if (!eipStatus[1]) {
          keys.push("JOB_MANAGEMENT_EXTENSION");
        }
        if (!eipStatus[2]) {
          keys.push("SCAN_TEMPLATE_MANAGEMENT");
        }
        if (!eipStatus[3]) {
          keys.push("SCAN_EXTENSION");
        }
        keys.push("CONTACT_ADMINISTRATOR_TO_ENABLE2")
        this.errorType = {
          titleKey: "EIP_SERVICES",
          keys: keys,
          exitApp: true,
        };

        this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
        this.loading = false;
        this.commonService.updateView();

        return;
      }

      const isInMaintainanceMode = await this.getMaintenanceModeValue();
      this.loading = false;

      if (isInMaintainanceMode) {
        this.maintenance = true;
        this.onLoadingClose = () =>
          setInterval(() => this.getAvailability(), 3000);
      } else {
        this.onLoadingClose = async () => {
          await this.deviceInfoService.getDeviceCapabilities();

          await this.login();
        };
      }

      this.commonService.updateView();
    }
    async getAvailability() {
      const isInMaintainanceMode = await this.getMaintenanceModeValue();
      if (!isInMaintainanceMode) this.maintenanceOff();
    }
    maintenanceOff() {
      this.maintenance = false;
      this.onMaintenanceClose = async () => {
        await this.login();
        this.errorType = this.errorService.errorTypes.appConfiguredIncorrectly;
        this.commonService.updateView();
        this.workflowService.showSelectWorkflow = true;
        // this.$state.go("workflow")
      };
    }
    async getMaintenanceModeValue() {
      const maintenanceConfig = await this.apiClientService.$http.get(
        "maintenance.json"
      );
      return maintenanceConfig.data.maintenanceMode;
    }
    async login() {
      try {
        this.shellDataBagService.setData();
        const shellData = this.shellDataBagService.getData();

        // Client ID
        // TODO: Comment out below line when back-end is ready for real ids
        const { clientId, deviceSN, serverUrl: initialServerUrl } = shellData;

        const appId = '19F0E46D-511F-4987-BA82-2B466BE366AC';

        const splitServerUrl = initialServerUrl.split('://');
        const serverUrl = splitServerUrl.length > 1 ? splitServerUrl[1] : initialServerUrl;

        this.gcpService.clientId = clientId;
        this.gcpService.appId = appId;
        this.gcpService.deviceSN = deviceSN;
        this.gcpService.serverUrl = serverUrl;

        await this.gcpService.postSession(clientId, appId, deviceSN, serverUrl);

        this.workflowService.showSelectWorkflow = true;
        this.$state.go("workflow");
      } catch (error) {
        this.errorType = this.errorService.errorTypes.appConfiguredIncorrectly;
        this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
        this.commonService.updateView();
      }
    }
    exitApp() {
      this.commonService.exitApp();
    }
  },
};
