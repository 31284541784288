/* --------------------------------------------------------------------------------------
error.service.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export class errorService {
  /* @ngInject */
  constructor(errorTypes, apiClientService, commonService, $state) {
    this.errorTypes = errorTypes;
    this.apiClientService = apiClientService;
    this.commonService = commonService;
    this.$state = $state;
  }
  getUnavailableServicesError(services) {
    const result = this.errorTypes.servicesUnavailable;
    result.dynamicKeys = services;
    return result;
  }
  getErrorByStatusCode(status) {
    switch (status) {
      case -1:
        return this.errorTypes.networkUnavailable;
      case 401:
        return this.errorTypes.appConfiguredIncorrectly;
      case 404:
        return this.errorTypes.networkUnavailable;
      case 408:
        return this.errorTypes.cannotConnectWithIntacct;
      case 409:
        return this.errorTypes.filenameExists;
      case 413:
        return this.errorTypes.sizeExceeded;
      default:
        return this.errorTypes.errorHasOccured;
    }
  }
  getErrorCallback(errorType) {
    //TODO: Merge these functions, then call errorType.closeFunc o similar
    if (errorType.logout) return () => this.apiClientService.logout();
    if (errorType.backToMain) return () => this.$state.go("main");
    if (errorType.exitApp) return () => this.commonService.exitApp();
    //Default, do nothing
    return angular.noop();
  }
}
