/* --------------------------------------------------------------------------------------
   privacyStatements.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const privacyStatementEnComponent = {
  template: require("./privacyStatement/privacy_en.html"),
  controllerAs: "vm",
  controller: class mainActionBarCtrl {
    /* @ngInject*/
    constructor(commonService) {
      this.commonService = commonService;
    }
    $onInit() {
      this.screenSize = this.commonService.getScreenSize();
    }
  },
};

export const privacyStatementEsComponent = {
  template: require("./privacyStatement/privacy_es.html"),
};

export const privacyStatementDeComponent = {
  template: require("./privacyStatement/privacy_de.html"),
};

export const privacyStatementFrComponent = {
  template: require("./privacyStatement/privacy_fr-ca.html"),
};

export const privacyStatementItComponent = {
  template: require("./privacyStatement/privacy_it.html"),
};

export const privacyStatementPtComponent = {
  template: require("./privacyStatement/privacy_pt-br.html"),
};

export const privacyStatementCsComponent = {
  template: require("./privacyStatement/privacy_cs.html"),
};

export const privacyStatementDaComponent = {
  template: require("./privacyStatement/privacy_da.html"),
};

export const privacyStatementNlComponent = {
  template: require("./privacyStatement/privacy_nl.html"),
};

export const privacyStatementRuComponent = {
  template: require("./privacyStatement/privacy_ru.html"),
};

export const privacyStatementRoComponent = {
  template: require("./privacyStatement/privacy_ro.html"),
};

export const privacyStatementPlComponent = {
  template: require("./privacyStatement/privacy_pl.html"),
};

export const privacyStatementSvComponent = {
  template: require("./privacyStatement/privacy_sv.html"),
};

export const privacyStatementTrComponent = {
  template: require("./privacyStatement/privacy_tr.html"),
};

export const privacyStatementNoComponent = {
  template: require("./privacyStatement/privacy_no.html"),
};

export const privacyStatementHuComponent = {
  template: require("./privacyStatement/privacy_hu.html"),
};

export const privacyStatementElComponent = {
  template: require("./privacyStatement/privacy_el.html"),
};

export const privacyStatementFiComponent = {
  template: require("./privacyStatement/privacy_fi.html"),
};

export const privacyStatementCaComponent = {
  template: require("./privacyStatement/privacy_ca.html"),
};
