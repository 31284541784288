/* --------------------------------------------------------------------------------------
apiClient.service.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export class apiClientService {
  /* @ngInject */
  constructor(
    appSettings,
    authService,
    $http,
    $window,
    $state
  ) {
    this.appSettings = appSettings;
    this.authService = authService;
    this.$http = $http;
    this.$window = $window;
    this.$state = $state;

    // TODO: Should be removed once api mocking is not the case
    this.isMock = false;
  }
  async get(uri) {
    const endpoint = this.getApiUrl() + uri;
    const result = await this.$http.get(endpoint, { headers: this.headers });
    return result.data;
  }
  async post(uri, postData) {
    const endpoint = this.getApiUrl() + uri;
    const result = await this.$http.post(endpoint, postData, {
      headers: this.headers,
    });
    return result.data;
  }
  async delete(uri) {
    const endpoint = this.getApiUrl() + uri;
    const result = await this.$http.delete(endpoint, { headers: this.headers });
    return result.data;
  }
  async ssoPost(uri, postData) {
    const endpoint = this.getSsoUrl() + uri;
    const result = await this.$http.post(endpoint, postData, {
      headers: this.headers,
    });
    return result.data;
  }
  async put(uri, postData) {
    const endpoint = this.getApiUrl() + uri;
    const result = await this.$http.put(endpoint, postData, {
      headers: this.headers,
    });
    return result.data;
  }
  getApiUrl() {
    if (!this.apiUrl) this.setApiUrl();
    return this.apiUrl;
  }
  getSsoUrl() {
    if (!this.apiUrl) this.setApiUrl();
    return this.apiUrl + "/ssoapi";
  }
  setApiUrl() {
    const host = this.$window.location.host;
    console.log("host is: " + host);

    if (host.includes("localhost")) this.isLocal = true;

    if (this.isLocal && this.appSettings.localhostApi == "local")
      this.apiUrl = this.appSettings.devApis.localhost;
    if (
      host.includes("-dev") ||
      (this.isLocal && this.appSettings.localhostApi == "dev")
    )
      this.apiUrl = this.appSettings.devApis.dev;
    else if (
      host.includes("-test") ||
      (this.isLocal && this.appSettings.localhostApi == "test")
    )
      this.apiUrl = this.appSettings.devApis.test;
    else if (
      host.includes("-staging") ||
      (this.isLocal && this.appSettings.localhostApi == "staging")
    )
      this.apiUrl = this.appSettings.devApis.staging;

    if (!this.apiUrl) this.apiUrl = this.appSettings.api;

    console.log("api is: " + this.apiUrl);
  }
  logout() {
    this.headers["token"] = null;
    this.isLoggedIn = false;
    this.authService.clearAuthCookie();
  }
  setServerUrl(serverUrl) {
    if (!this.headers) this.headers = {};
    this.headers["x-server-url"] = serverUrl;
  }
  setTokenAuthHeader(token, appId, clientId, deviceSN, serverUrl) {
    if (!this.headers) this.headers = {};
    this.headers["token"] = token;
    this.headers["x-session"] = token;
    this.headers["appID"] = appId;
    this.headers["clientId"] = clientId;
    this.headers["deviceSN"] = deviceSN;
    //this.headers["x-mock-data"] = this.isMock ? 1 : 0;
    this.isLoggedIn = true;
  }
}
