/* --------------------------------------------------------------------------------------
   app.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

import angular from "angular";
import uiRouter from "@uirouter/angularjs";
import "angular-translate";
import "angular-translate-loader-static-files";

require("angular-moment");
require("ng-mask");
require("angular-sanitize");
require("angular-cookies");

import { appCommonModule } from "./common/common";
import { appXrxWrappersModule } from "./xrxWrappers/xrxWrappers";

import { appWorkflowModule } from "./workflow/workflow.module";
import { appScanModule } from "./scan/scan.module";
import { appWorkflowDetailsModule } from "./workflowDetails/workflowDetails.module";

import { xrxDependencies } from "./xrxDependencies/xrxDependencies.module";

import { appVersionComponent } from "./components/appVersion.component";
import { landingComponent } from "./components/landing.component";
import { privacyStatementComponent } from "./components/privacyStatement.component";

import { xeroxSSOComponent } from "./components/xeroxSSO.component";
import { xeroxSSORedirectComponent } from "./components/xeroxSSORedirect.component";

import {
  privacyStatementEnComponent,
  privacyStatementEsComponent,
  privacyStatementDeComponent,
  privacyStatementDaComponent,
  privacyStatementCsComponent,
  privacyStatementFrComponent,
  privacyStatementItComponent,
  privacyStatementPtComponent,
  privacyStatementRuComponent,
  privacyStatementPlComponent,
  privacyStatementRoComponent,
  privacyStatementSvComponent,
  privacyStatementNlComponent,
  privacyStatementCaComponent,
  privacyStatementElComponent,
  privacyStatementFiComponent,
  privacyStatementHuComponent,
  privacyStatementNoComponent,
  privacyStatementTrComponent,
} from "./components/privacyStatements.component";

import { gcpService } from "./services/gcp.service";
import { privacyStatementLanguageService } from "./services/privacyStatementLanguage.service";
import { scanService } from "./services/scan.service";

import { appSettings } from "./constants/appSettings.const";
import { xeroxEIPParameters } from "./constants/xeroxEIPParameters.const";
import { errorTypes } from "./constants/errorTypes.const";
import { general } from "./constants/general.const";
import { workflowService } from "./services/workflow.service";

export default angular
  .module("app", [
    "ui.router",
    "angularMoment",
    "pascalprecht.translate",
    "ngMask",
    "ngSanitize",
    "ngCookies",
    "app.common",
    "app.workflow",
    "app.workflowDetails",
    "app.scan",
    "app.xrxWrappers",
    "app.xrxDependencies",
  ])
  .component("landing", landingComponent)
  .component("appVersion", appVersionComponent)
  .component("privacyStatement", privacyStatementComponent)
  .component("xeroxSSO", xeroxSSOComponent)
  .component("xeroxSSORedirect", xeroxSSORedirectComponent)
  .component("privacyStatementEn", privacyStatementEnComponent)
  .component("privacyStatementEs", privacyStatementEsComponent)
  .component("privacyStatementCs", privacyStatementCsComponent)
  .component("privacyStatementDe", privacyStatementDeComponent)
  .component("privacyStatementDa", privacyStatementDaComponent)
  .component("privacyStatementFr", privacyStatementFrComponent)
  .component("privacyStatementIt", privacyStatementItComponent)
  .component("privacyStatementPt", privacyStatementPtComponent)
  .component("privacyStatementRu", privacyStatementRuComponent)
  .component("privacyStatementPl", privacyStatementPlComponent)
  .component("privacyStatementRo", privacyStatementRoComponent)
  .component("privacyStatementSv", privacyStatementSvComponent)
  .component("privacyStatementNl", privacyStatementNlComponent)
  .component("privacyStatementCa", privacyStatementCaComponent)
  .component("privacyStatementEl", privacyStatementElComponent)
  .component("privacyStatementFi", privacyStatementFiComponent)
  .component("privacyStatementHu", privacyStatementHuComponent)
  .component("privacyStatementNo", privacyStatementNoComponent)
  .component("privacyStatementTr", privacyStatementTrComponent)

  .service("gcpService", gcpService)
  .service("privacyStatementLanguageService", privacyStatementLanguageService)
  .service("scanService", scanService)
  .service("workflowService", workflowService)
  .constant("appSettings", appSettings)
  .constant("xeroxEIPParameters", xeroxEIPParameters)
  .constant("errorTypes", errorTypes)
  .constant("general", general)

  .config(($stateProvider, $urlRouterProvider, $locationProvider) => {
    const setLanguageFunction = ($translate) => {
      return $translate.onReady().then(() => {
        const browserLang = navigator.language.substring(0, 2); //'en'
        $translate.use(browserLang);
      });
    };
    $locationProvider.html5Mode(true);
    $stateProvider
      .state("landing", {
        component: "landing",
        url: "/",
        resolve: {
          translateReady: setLanguageFunction,
        },
      })
      .state("workflow", {
        component: "workflow",
      })
      .state("workflowDetails", {
        component: "workflowDetails",
      })
      .state("scan", {
        component: "scan",
      })

    $urlRouterProvider.otherwise("/");
  })
  .config(($translateProvider) => {
    /* @ngInject*/
    $translateProvider.useStaticFilesLoader({
      files: [
        {
          prefix: "./i18n/locale-",
          suffix: ".json",
        },
      ],
    });
    $translateProvider.preferredLanguage("en");
    $translateProvider.useSanitizeValueStrategy("sanitizeParameters");
  })
  .run(sessionDataService => {
    sessionDataService.setDeviceIp();
  })
  .filter("trusted", [
    "$sce",
    function ($sce) {
      // ES6 this
      return function (url) {
        return $sce.trustAsResourceUrl(url);
      };
    },
  ]);
