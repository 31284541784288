/* --------------------------------------------------------------------------------------
   scan.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const scanComponent = {
  template: require("./scan.html"),
  controllerAs: "vm",
  controller: class scanController {
    /* @ngInject */
    constructor(
      appSettings,
      commonService,
      jobStatusService,
      printService,
      deviceInfoService,
      errorService,
      scanScreenService,
      $state,
      $translate,
      moment
    ) {
      this.appSettings = appSettings;
      this.commonService = commonService;
      this.jobStatusService = jobStatusService;
      this.printService = printService;
      this.deviceInfoService = deviceInfoService;
      this.errorService = errorService;
      this.scanScreenService = scanScreenService;
      this.$state = $state;
      this.$translate = $translate;
      this.moment = moment;
    }

    async $onInit() {
      this.loading = true;
      this.isPrintFinished = false;
      this.commonService.updateView();

      try {
        this.screenSize = this.commonService.getScreenSize();
        await this.scanScreenService.setOptions();
        const lighterLabel = await this.$translate("LIGHTER");
        const darkerLabel = await this.$translate("DARKER");
        const normalLabel = await this.$translate("NORMAL");

        this.lightenDarkenLabels = {
          "-3": `${lighterLabel} -3`,
          "-2": `${lighterLabel} -2`,
          "-1": `${lighterLabel} -1`,
          0: normalLabel,
          1: `${darkerLabel} +1`,
          2: `${darkerLabel} +2`,
          3: `${darkerLabel} +3`,
        };

        angular.element("#lighten-darken-slider").xrxslider({
          customFeedbackLabels: this.lightenDarkenLabels,
          widgetSize: "xrx-small",
          customChannel: true,
          range: false,
          min: -3,
          max: 3,
          showTicks: true,
          slide: (event, ui) => this.onChangeLightenDarken(),
        });

        angular
          .element("#lighten-darken-slider")
          .xrxslider("value", this.scanScreenService.lightenDarken);

        this.loading = false;
        this.commonService.updateView();
      } catch (error) {
        this.errorType = error.titleKey
          ? error
          : this.errorService.getErrorByStatusCode(error.status);
        this.errorService.getErrorCallback(this.errorType);
        this.onErrorClose = () => {
          this.errorType = null;
          this.commonService.updateView();
        };
        this.commonService.updateView();
      } finally {
        this.refreshScroll();
        this.changeSelectors();
      }
    }

    async ok() {
      try {
        if (
          this.scanScreenService.emailSettings &&
          !this.scanScreenService.emailAddress
        ) {
          this.showEnterEmailAddressBanner();
          return;
        }

        if (
          this.scanScreenService.emailSettings &&
          !this.validateEmail(this.scanScreenService.emailAddress)
        ) {
          this.showInvalidEmailAddressBanner();
          return;
        }

        this.scanScreenService.saveScanSettings();

        this.isProcessing = true;
        this.processingLabel = "SCANNING";
        this.commonService.updateView();
        await this.scanScreenService.scan();
        this.isProcessing = true;
        this.commonService.updateView();

        this.processingLabel = "PROCESSING";
        this.commonService.updateView();

        await this.scanScreenService.confirmUpload();

        // Print if print settings is on
        if (this.scanScreenService.printSettings) {
          this.isProcessing = true;
          this.processingLabel = "PRINTING_CONFIRMATION";
          this.commonService.updateView();
          setTimeout(() => {
            angular
              .element(".xrx-activity-indicator")
              .xrxactivityindicator("complete");
            this.processingLabel = "GCP_RECEIVED_YOUR_INFO";
            this.subtitle = "YOUR_CONFIRMATION_IS_PRINTING_NOW";
            this.commonService.updateView();
          }, 2000);

          this.isPrintFinished = false;

          setTimeout(() => {
            if (!this.isPrintFinished) {
              this.completeWorkflow();
            }
          }, 10000);

          await this.scanScreenService.print();

          this.isPrintFinished = true;
          this.commonService.updateView();
        }

        this.completeWorkflow();
      } catch (error) {
        this.showCustomError(error);

        return;
      }
    }

    async preview() {
      try {
        if (
          this.scanScreenService.emailSettings &&
          !this.scanScreenService.emailAddress
        ) {
          this.showEnterEmailAddressBanner();
          return;
        }

        if (
          this.scanScreenService.emailSettings &&
          !this.validateEmail(this.scanScreenService.emailAddress)
        ) {
          this.showInvalidEmailAddressBanner();
          return;
        }

        this.scanScreenService.saveScanSettings();

        this.isProcessing = true;
        this.processingLabel = "SCANNING";
        this.commonService.updateView();
        await this.scanScreenService.scan();

        this.processingLabel = "PROCESSING";
        this.commonService.updateView();

        this.isProcessing = false;
        this.commonService.updateView();

        await this.scanScreenService.pageCount();

        this.scanScreenService.showSelectPreviewPopup = true;
        this.commonService.updateView();
      } catch (error) {
        this.showCustomError(error);

        return;
      }
    }

    async onPreviewProceed() {
      const spinnerLabel = this.getSpinnerLabelAfterPreview()
      this.processingLabel = spinnerLabel[0]
      if (spinnerLabel[1]) this.subtitle = this.scanScreenService.emailAddress + "."

      this.isProcessing = true;
      this.commonService.updateView();
      
      try {
        await this.scanScreenService.confirmUpload();        
      } catch (error) {
        return this.showCustomError(error)
      }

      if (this.scanScreenService.printSettings) {
        await this.printConfirmation()
      }
    
      this.completeWorkflow();
    }

    getSpinnerLabelAfterPreview() {
      if (this.scanScreenService.printSettings) return [ 'PRINTING_CONFIRMATION', false ]
      if (this.scanScreenService.emailSettings) return [ 'ON_WORKFLOW_COMPLETE', true ]
      return [ 'PROCESSING', false ]
    } 

    async printConfirmation() {
      setTimeout(() => {
        angular
          .element(".xrx-activity-indicator")
          .xrxactivityindicator("complete");
        this.processingLabel = "GCP_RECEIVED_YOUR_INFO";
        this.subtitle = "YOUR_CONFIRMATION_IS_PRINTING_NOW";
        this.commonService.updateView();
      }, 2000);

      this.isPrintFinished = false;

      setTimeout(() => {
        if (!this.isPrintFinished) {
          this.completeWorkflow();
        }
      }, 10000);

      await this.scanScreenService.print();

      this.isPrintFinished = true;
      this.commonService.updateView();
    }

    completeWorkflow() {
      if (!this.workflowCompleted) {
        this.workflowCompleted = true
        if (
          this.scanScreenService.emailSettings &&
          this.scanScreenService.emailAddress
        ) {
          this.isProcessing = true;
          this.processingLabel = "ON_WORKFLOW_COMPLETE";
          this.subtitle = this.scanScreenService.emailAddress + ".";
          this.commonService.updateView();
  
          setTimeout(() => {
            angular
              .element(".xrx-activity-indicator")
              .xrxactivityindicator("complete");
          }, 50);
        } else if (
          !this.scanScreenService.printSettings &&
          !this.scanScreenService.emailSettings
        ) {
          this.isProcessing = true;
          this.processingLabel = "GCP_RECEIVED_YOUR_INFO";
          this.commonService.updateView();
  
          setTimeout(() => {
            angular
              .element(".xrx-activity-indicator")
              .xrxactivityindicator("complete");
          }, 50);
        }
  
        if (!this.errorType) {
          setTimeout(() => {
            this.$state.go("landing");
          }, 2000);
        }
      }
    }

    async reset() {
      await this.scanScreenService.setDefaults();
      this.commonService.updateView();
      this.changeSelectors();
      angular
        .element("#lighten-darken-slider")
        .xrxslider("value", this.scanScreenService.lightenDarken);
    }

    changeSelectors() {
      this.changeOutputColorSelector();
      this.changeTwoSidedScanSelector();
      this.changeOriginalOrientationSelector();
      this.changeOriginalSizeSelector();
      this.changeOriginalTypeSelector();
      this.changeResolutionSelector();
    }

    validateEmail(email) {
      const re = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      return re.test(email);
    }

    showCustomError(error) {
      this.isProcessing = false;
      this.commonService.updateView();

      if (error.titleKey) {
        this.errorType = error;
      } else {
        this.errorType = this.errorService.errorTypes.errorHasOccured;
      }
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
      this.commonService.updateView();
    }

    selectOutputColor(value) {
      this.scanScreenService.outputColor =
        this.scanScreenService.outputColorOptions[value.index].key;
      this.scanScreenService.outputColorText = value.label;
      this.scanScreenService.outputColorIndex = value.index;
      this.commonService.updateView();

      this.changeOutputColorSelector();
    }

    changeOutputColorSelector() {
      angular
        .element("#output-color-selector-menu li")
        .removeClass("ui-state-focus ui-state-selected");
      angular
        .element("#output-color-selector-menu li")
        .eq(this.scanScreenService.outputColorIndex)
        .addClass("ui-state-selected");
      this.commonService.updateView();
    }

    selectTwoSidedScan(value) {
      this.scanScreenService.twoSidedScan =
        this.scanScreenService.twoSidedScanOptions[value.index].key;
      this.scanScreenService.twoSidedScanText = value.label;
      this.scanScreenService.twoSidedScanIndex = value.index;
      this.commonService.updateView();

      this.changeTwoSidedScanSelector();
    }

    changeTwoSidedScanSelector() {
      angular
        .element("#two-sided-scan-selector-menu li")
        .removeClass("ui-state-focus ui-state-selected");
      angular
        .element("#two-sided-scan-selector-menu li")
        .eq(this.scanScreenService.twoSidedScanIndex)
        .addClass("ui-state-selected");
      this.commonService.updateView();
    }

    selectOriginalType(value) {
      this.scanScreenService.originalType =
        this.scanScreenService.originalTypeOptions[value.index].key;
      this.scanScreenService.originalTypeImage =
        this.scanScreenService.originalTypeOptions[value.index].img;
      this.scanScreenService.originalTypeText = value.label;
      this.scanScreenService.originalTypeIndex = value.index;
      this.commonService.updateView();

      this.changeOriginalTypeSelector();
    }

    changeOriginalTypeSelector() {
      angular
        .element("#original-type-selector-menu li")
        .removeClass("ui-state-focus ui-state-selected");
      angular
        .element("#original-type-selector-menu li")
        .eq(this.scanScreenService.originalTypeIndex)
        .addClass("ui-state-selected");
      this.commonService.updateView();
    }

    selectOriginalSize(value) {
      const selectedSize =
        this.scanScreenService.originalSizeOptions[value.index];
      this.scanScreenService.originalSize = selectedSize.key;
      this.scanScreenService.originalSizeText = selectedSize.value;
      this.scanScreenService.originalSizeOrientation = selectedSize.orientation;
      this.scanScreenService.originalSizeIndex = value.index;
      this.commonService.updateView();

      this.changeOriginalSizeSelector();
    }

    changeOriginalSizeSelector() {
      angular
        .element("#original-size-selector-menu li")
        .removeClass("ui-state-focus ui-state-selected");
      angular
        .element("#original-size-selector-menu li")
        .eq(this.scanScreenService.originalSizeIndex)
        .addClass("ui-state-selected");
      this.commonService.updateView();
    }

    selectResolution(value) {
      this.scanScreenService.resolution =
        this.scanScreenService.resolutionOptions[value.index].key;
      this.scanScreenService.resolutionText = value.label;
      this.scanScreenService.resolutionValue = this.scanScreenService.resolutionOptions[value.index].resolution;
      this.scanScreenService.resolutionIndex = value.index;
      this.commonService.updateView();


      this.changeResolutionSelector();

      // TODO: If required to show banner for high resolution then comment out below lines
      // if (this.scanScreenService.resolution === "600") {
      //   angular.element("#high-resolution-banner").xrxbanner("open");
      // }
    }

    changeResolutionSelector() {
      angular
        .element("#resolution-selector-menu li")
        .removeClass("ui-state-focus ui-state-selected");
      angular
        .element("#resolution-selector-menu li")
        .eq(this.scanScreenService.resolutionIndex)
        .addClass("ui-state-selected");
      this.commonService.updateView();
    }

    selectOriginalOrientation(value) {
      this.scanScreenService.originalOrientation =
        this.scanScreenService.originalOrientationOptions[value.index].key;
      this.scanScreenService.originalOrientationText = value.label;
      this.scanScreenService.originalOrientationIndex = value.index;
      this.commonService.updateView();
    }

    changeOriginalOrientationSelector() {
      angular
        .element("#original-orientation-selector-menu li")
        .removeClass("ui-state-focus ui-state-selected");
      angular
        .element("#original-orientation-selector-menu li")
        .eq(this.scanScreenService.originalOrientationIndex)
        .addClass("ui-state-selected");
      this.commonService.updateView();
    }

    getOrientationGlyph(orientation) {
      switch (orientation) {
        case "L":
          return "glyphicon-short-edge-feed";
        case "P":
          return "glyphicon-long-edge-feed";
        default:
          return null;
      }
    }

    showInvalidEmailAddressBanner() {
      angular.element("#email-not-valid-format-banner").xrxbanner("call");
    }

    showEnterEmailAddressBanner() {
      angular.element("#enter-email-address-banner").xrxbanner("call");
    }

    refreshScroll() {
      if (angular.element("#scan-scanSettings-container")) {
        angular
          .element("#scan-scanSettings-container")
          .xrxscrollable("refresh");
        this.commonService.updateView();
      } else {
        setTimeout(() => {
          this.refreshScroll();
        }, 300);
      }
    }

    onChangeLightenDarken() {
      setTimeout(() => {
        const lightenDarken = angular
          .element("#lighten-darken-slider")
          .xrxslider("value");
        this.scanScreenService.lightenDarken = lightenDarken;
      }, 500);
    }
  },
};
