/* --------------------------------------------------------------------------------------
   scan.service.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class scanService {
  /*@ngInject*/
  constructor(
    apiClientService,
    errorService,
    deviceInfoService,
    xeroxEIPParameters,
    workflowScanningService,
    sessionDataService,
    $translate
  ) {
    this.apiClientService = apiClientService;
    this.errorService = errorService;
    this.deviceInfoService = deviceInfoService;
    this.xeroxEIPParameters = xeroxEIPParameters;
    this.workflowScanningService = workflowScanningService;
    this.sessionDataService = sessionDataService;
    this.$translate = $translate;
  }
  async getThumbStatus(scanProfileId, pageNum) {
    try {
      const blob = await this.apiClientService.get(
        `/scan/preview/${scanProfileId}/${pageNum}`
      );
      return !!blob;
    } catch (error) {
      throw error;
    }
  }
  getScanThumbnailUrl(scanProfileId, pageNum) {
    try {
      const thumbUrl =
        this.apiClientService.getApiUrl() +
        `/scan/preview/${scanProfileId}/${pageNum}`;
      return thumbUrl;
    } catch (error) {
      throw error;
    }
  }

  async scan(data) {
    try {
      const scanTicket = await this.apiClientService.post("/scan/ticket", data);
      const scanJob = await this.workflowScanningService.submitScanTicket(
        scanTicket,
        this.sessionDataService.deviceUrl
      );
      return xrxScanV2ParseInitiateScanJob(scanJob.response);
    } catch (error) {
      throw error;
    }
  }

  async confirmUpload(scanProfileId) {
    try {
      const response = await this.apiClientService.put(
        `/scan/${scanProfileId}`,
        {}
      );
      return response;
    } catch (error) {
      if (error.status === 413) {
        throw this.errorService.errorTypes.sizeExceeded;
      }
      throw this.errorService.errorTypes.confirmUploadFailed;
    }
  }

  async getPageCount(scanProfileId) {
    try {
      const response = await this.apiClientService.get(
        `/scan/pages/${scanProfileId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getMaxSize() {
    try {
      const maxSize = await this.apiClientService.get("/scan/maxsize");
      return maxSize;
    } catch (error) {
      throw error;
    }
  }
  async getScanSize(scanProfileId) {
    try {
      const size = await this.apiClientService.get(
        `/scan/${scanProfileId}/length`
      );
      return size;
    } catch (error) {
      throw error;
    }
  }
}
