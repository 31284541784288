/* --------------------------------------------------------------------------------------
   xwScrollable.directive.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

/*eslint semi: ["error", "never"]*/
 export class xwScrollable {
  /*@ngInject*/
  constructor ($timeout, appSettings) {
    this.restrict = 'A'
    this.scope = {}
    this.$timeout = $timeout
    this.appSettings = appSettings
  }
  link(scope, elem, attrs) {
    const element = elem.first()
    const options = {
      zoom: (attrs.zoom == "true"),
      tap: (attrs.tap == "true"),
      height: attrs.height,
      width: attrs.width,
      theme: attrs.theme? attrs.theme : this.appSettings.primaryColor,
      noScrollIndicator: (attrs.noScrollIndicator == true),
      noDropShadow: (attrs.nodropshadow == 'true'),
    }
    this.$timeout(() => {element.xrxscrollable(options)})

    attrs.$observe('zoomLevel', (val) => {
        element.xrxscrollable('getIScroll').zoom(val)
    });
    
    this.$timeout(() => {
      //refresh scroll on resize
      scope.$watch(
        () => {
          if (element.find('.scrollbar') && element.find('.scrollbar')[0]) {
            return element.find('.scroller')[0].offsetHeight
          }
        },
        (newVal, oldVal) => {
          if (newVal !== oldVal)
            element.xrxscrollable('refresh')
        }
      )
    },50)
  }
  static directiveFactory($timeout, appSettings) {
    xwScrollable.instance = new xwScrollable($timeout, appSettings)
    return xwScrollable.instance
  }
}
