/* --------------------------------------------------------------------------------------
  scan.service.js
  Copyright © 2021 Xerox Corporation. All Rights Reserved.

  Copyright protection claimed includes all forms and matters of copyrightable material
  and information now allowed by statutory or judicial law or hereinafter granted,
  including without limitation, material generated from the software programs which
  are displayed on the screen such as icons, screen display looks, etc.
  -------------------------------------------------------------------------------------
*/

export class scanScreenService {
  /* @ngInject*/
  constructor(
    xeroxEIPParameters,
    deviceInfoService,
    apiClientService,
    commonService,
    workflowScanningService,
    sessionDataService,
    templateService,
    shellDataBagService,
    scanService,
    printService,
    jobStatusService,
    workflowService,
    sessionService,
    $translate
  ) {
    this.xeroxEIPParameters = xeroxEIPParameters;
    this.deviceInfoService = deviceInfoService;
    this.apiClientService = apiClientService;
    this.commonService = commonService;
    this.workflowScanningService = workflowScanningService;
    this.sessionDataService = sessionDataService;
    this.templateService = templateService;
    this.shellDataBagService = shellDataBagService;
    this.scanService = scanService;
    this.printService = printService;
    this.jobStatusService = jobStatusService;
    this.workflowService = workflowService;
    this.sessionService = sessionService;
    this.$translate = $translate;
  }

  async setOptions() {
    this.twoSidedScanOptions = this.xeroxEIPParameters.sides.filter((side) =>
      side.scope.includes("scan")
    );
    this.originalOrientationOptions = this.xeroxEIPParameters.orientations;

    const deviceCapabilities =
      await this.deviceInfoService.getDeviceCapabilities();
    const isA3 = await this.deviceInfoService.isA3();

    this.originalTypeOptions = this.xeroxEIPParameters.types;
    this.originalSizeOptions = isA3
      ? this.xeroxEIPParameters.sizes
      : this.xeroxEIPParameters.sizes.filter((s) => !s.a3);
    const outputColorOptionsLoader = this.xeroxEIPParameters.colorModes;
    this.outputColorOptions = deviceCapabilities.colorSupport
      ? outputColorOptionsLoader
      : outputColorOptionsLoader.filter((color) => color.key !== "Fullcolor");
    this.outputColorIndex = 0;

    const isAltalink = await this.commonService.isAltalink()
    this.resolutionOptions = isAltalink? 
      this.xeroxEIPParameters.resolutions :
      this.xeroxEIPParameters.resolutions.filter(r => !r.alta);
    await this.setDefaults();
  }

  async setDefaults() {
    const savedScanSettingsString = localStorage.getItem("scanSettings") || "";
    const savedScanSettings =
      savedScanSettingsString === "" ? {} : JSON.parse(savedScanSettingsString);

    const selectedWorkflowScanSettings =
      savedScanSettings[this.workflowService.selectedWorkflow.workflowId] || {};

    this.previewSettings =
      selectedWorkflowScanSettings.previewSettings || false;
    this.emailSettings = selectedWorkflowScanSettings.emailSettings || false;
    this.printSettings =
      typeof selectedWorkflowScanSettings.printSettings === "undefined"
        ? true
        : selectedWorkflowScanSettings.printSettings;

    this.emailAddress =
      selectedWorkflowScanSettings.emailAddress ||
      (await this.getDefaultEmailAddress());

    this.twoSidedScan =
      selectedWorkflowScanSettings.twoSidedScan ||
      this.twoSidedScanOptions[1].key;
    this.twoSidedScanOption = this.twoSidedScanOptions.find(
      (option) => option.key === this.twoSidedScan
    );
    this.twoSidedScanImage = this.twoSidedScanOption.img;
    this.twoSidedScanText = await this.$translate(
      this.twoSidedScanOption.translationKey
    );
    this.twoSidedScanIndex = this.twoSidedScanOptions.findIndex(
      (item) => item.key == this.twoSidedScan
    );

    this.originalOrientation =
      selectedWorkflowScanSettings.originalOrientation ||
      this.originalOrientationOptions[0].key;
    this.originalOrientationOption = this.originalOrientationOptions.find(
      (option) => option.key === this.originalOrientation
    );
    this.originalOrientationImage = this.twoSidedScanOption.img;
    this.originalOrientationText = await this.$translate(
      this.originalOrientationOption.translationKey
    );
    this.originalOrientationIndex = this.originalOrientationOptions.findIndex(
      (item) => item.key == this.originalOrientation
    );

    this.originalType =
      selectedWorkflowScanSettings.originalType ||
      this.originalTypeOptions[0].key;
    this.originalTypeOption = this.originalTypeOptions.find(
      (option) => option.key === this.originalType
    );
    this.originalTypeImage = this.originalTypeOption.img;
    this.originalTypeText = await this.$translate(
      this.originalTypeOption.translationKey
    );
    this.originalTypeIndex = this.originalTypeOptions.findIndex(
      (item) => item.key == this.originalType
    );

    this.originalSize =
      selectedWorkflowScanSettings.originalSize ||
      this.originalSizeOptions[2].key;
    this.originalSizeOption = this.originalSizeOptions.find(
      (option) => option.key === this.originalSize
    );
    this.originalSizeText = this.originalSizeOption.value;
    this.originalSizeOrientation = this.originalSizeOption.orientation;
    this.originalSizeIndex = this.originalSizeOptions.findIndex(
      (item) => item.key == this.originalSize
    );

    this.resolution =
      selectedWorkflowScanSettings.resolution || "300";
    this.resolutionOption = this.resolutionOptions.find(
      (option) => option.key === this.resolution
    );
    this.resolutionText = this.resolutionOption.value;
    this.resolutionValue = this.resolutionOption.resolution;
    this.resolutionIndex = this.resolutionOptions.findIndex(
      (item) => item.key == this.resolution
    );

    this.outputColor =
      selectedWorkflowScanSettings.outputColor ||
      this.outputColorOptions[0].key;
    this.outputColorOption = this.outputColorOptions.find(
      (option) => option.key === this.outputColor
    );
    this.outputColorText = await this.$translate(
      this.outputColorOption.translationKey
    );
    this.outputColorIndex = this.outputColorOptions.findIndex(
      (item) => item.key == this.outputColor
    );

    this.lightenDarken = selectedWorkflowScanSettings.lightenDarken || 0;

    this.copies = 1;
  }

  async getDefaultEmailAddress() {
    let result = "";
    try {
      result = await this.sessionService.getDeviceFromAddress();
    } catch {
      // this is to avoid throwing the error
    }
    return result;
  }

  async scan() {
    try {
      this.shellDataBagService.setData();
      const shellData = this.shellDataBagService.getData();

      const { deviceSN } = shellData;

      this.scanProfileId = this.commonService.getNewGuid();
      const username = await this.getUsername();
      const a4SizePref = await this.deviceInfoService.getPaperPreferenceA4();

      const languageCode = this.getTwoDigitLanguage();
      const jobId = await this.scanService.scan({
        token: this.scanProfileId,
        timestamp: new Date().toISOString(),
        duplex: this.twoSidedScan,
        resolution: this.resolutionValue,
        colorMode: this.outputColor,
        orientation: this.originalOrientation,
        originalSize: this.originalSize,
        originalType: this.originalType,
        darkness: this.lightenDarken,
        workflowName: this.workflowService.selectedWorkflow.workflowName,
        workflowID: this.workflowService.selectedWorkflow.workflowId,
        metadata: this.getMetaDataForScan(),
        username,
        preview: this.previewSettings || this.printSettings,
        language: languageCode == 'pt' ? 'pt-br' : languageCode,
        emailTarget: this.emailSettings ? this.emailAddress : null,
        printsize: a4SizePref ? "A4" : "letter",
        deviceSN,
      });
      await this.jobStatusService.getScanFinishedStatus(jobId);
    } catch (error) {
      throw error;
    }
  }
  getTwoDigitLanguage() {
    return navigator.language.split("-")[0];
  }
  async getUsername() {
    let result = "";
    try {
      result = await this.sessionService.getDeviceUsername();
    } catch {
      // this is to avoid throwing the error
    }
    return result;
  }
  async pageCount() {
    try {
      const response = await this.scanService.getPageCount(this.scanProfileId);
      this.count = response.pages;
      return response.pages;
    } catch (error) {
      throw error;
    }
  }

  async confirmUpload() {
    try {
      const response = await this.scanService.confirmUpload(this.scanProfileId);
      this.blobUrl = response.url;
    } catch (error) {
      throw error;
    }
  }

  async print() {
    try {
      const printOptions = {
        copies: 1,
        sides: "OneSided",
        staple: "None",
        color: "Auto",
      };
      this.printJobId = await this.printService.SubmitPrint(
        this.blobUrl,
        this.scanProfileId + ".pdf",
        printOptions
      );
      const result = await this.jobStatusService.getPrintFinishedStatus(
        this.printJobId
      );
      if (
        result == "JobCanceledByUser" ||
        result == "CancelByUser" ||
        result == "None"
      ) {
        throw "JobCanceledByUser";
      } else if (result === "NetworkControllerError") {
        throw "NetworkControllerError";
      }
    } catch (error) {
      throw error;
    }
  }

  saveScanSettings() {
    const savedScanSettingsString = localStorage.getItem("scanSettings") || "";
    const savedScanSettings =
      savedScanSettingsString === "" ? {} : JSON.parse(savedScanSettingsString);

    localStorage.setItem(
      "scanSettings",
      JSON.stringify({
        ...savedScanSettings,
        [this.workflowService.selectedWorkflow.workflowId]: {
          twoSidedScan: this.twoSidedScan,
          resolution: this.resolution,
          outputColor: this.outputColor,
          originalOrientation: this.originalOrientation,
          originalSize: this.originalSize,
          originalType: this.originalType,
          emailSettings: this.emailSettings,
          emailAddress: this.emailAddress,
          printSettings: this.printSettings,
          previewSettings: this.previewSettings,
          lightenDarken: this.lightenDarken,
        },
      })
    );
  }

  getScanThumbnailUrl(pageNum) {
    try {
      if (this.scanProfileId) {
        return this.scanService.getScanThumbnailUrl(
          this.scanProfileId,
          pageNum
        );
      }
    } catch (error) {
      throw error;
    }
  }

  getScanThumbnailStatus(pageNum) {
    try {
      return this.scanService.getThumbStatus(this.scanProfileId, pageNum);
    } catch (error) {
      throw error;
    }
  }

  async getUploadStatus(scanProfileId) {
    try {
      const fn = () =>
        this.apiClientService.get(`/scan/complete/${scanProfileId}`);
      const fnCondition = (result) => result !== true;
      const scanStatus = await this.poll(fn, fnCondition, 1000);
      return scanStatus;
    } catch (error) {
      throw error;
    }
  }

  async getDocumentSendStatus(scanProfileId) {
    try {
      return this.apiClientService.get(`/v1/status?token=${scanProfileId}`);
    } catch (error) {
      throw error;
    }
  }

  delete(scanProfileId) {
    try {
      return this.templateService.delete(
        this.sessionDataService.deviceUrl,
        `${scanProfileId}.xst`,
        this.scanCheckSum
      );
    } catch (error) {
      throw error;
    }
  }

  async poll(fn, fnCondition, ms) {
    let result = await fn();
    while (fnCondition(result)) {
      await this.wait(ms);
      result = await fn();
    }
    return result;
  }

  wait(ms = 1000) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  getMetaDataForScan() {
    return this.workflowService.workFlowDetails.map(m => {
      m.metadataID,
      m.value
    }) || []
  }
}
