/* --------------------------------------------------------------------------------------
   deviceInfo.service.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class deviceInfoService {
  /*@ngInject*/
  constructor(sessionDataService, snmpService) {
    this.sessionDataService = sessionDataService;
    this.snmpService = snmpService;
  }
  async get() {
    if (this.deviceInfo) return Promise.resolve(this.deviceInfo);

    const deviceConfigDeviceInformation = await this.getDeviceConfigGetDeviceInformation();
    // SET SOME FATAL ERROR AFTER THIS
    if (!deviceConfigDeviceInformation) return Promise.reject();

    const domDeviceConfigDeviceInformation = xrxGetElementValue(
      xrxStringToDom(deviceConfigDeviceInformation),
      "Information"
    );
    const info = xrxStringToDom(domDeviceConfigDeviceInformation);

    const result = {
      name: $(info).find("DeviceInformation > device > name").text(),
      mac: $(info).find("DeviceInformation > device > mac").text(),
      serial: $(info).find("DeviceInformation > device > serial").text(),
      model: $(info).find("DeviceInformation > device > model").text(),
      canvasWidth: $(info)
        .find("DeviceInformation > display > canvasSize > width")
        .text(),
      canvasHeight: $(info)
        .find("DeviceInformation > display > canvasSize > height")
        .text(),
      styleGeneration: $(info)
        .find("DeviceInformation > style > generation")
        .text(),
      systemSoftware: $(info)
        .find("DeviceInformation > version > systemSoftware")
        .text(),
      uiSoftware: $(info)
        .find("DeviceInformation > version > uiSoftware")
        .text(),
      netControllerSoftware: $(info)
        .find("DeviceInformation > version > netControllerSoftware")
        .text(),
      //eipSoftware: getEipVersion(info)
    };

    this.deviceInfo = result;
    return result;
  }
  async isA3() {
    const deviceInfo = await this.get()

    if (deviceInfo.model.match(/Alta.*/))
      return true; //altalink a3

    if (deviceInfo.model.match(/Versa.*[CB]\d\d\d\d/))
      return true; //verslink a3

    return false; //versalink a4
  }
  //TODO: Move to a capabilities service
  async getDeviceCapabilities() {
    if (this.deviceCapabilities) {
      return this.deviceCapabilities;
    }

    const deviceConfigDeviceCapabilities = await this.getDeviceConfigGetDeviceCapabilities();
    if (!deviceConfigDeviceCapabilities) return Promise.reject();

    // Media size capability
    const domDeviceConfigDeviceCapabilities = xrxGetElementValue(
      xrxStringToDom(deviceConfigDeviceCapabilities),
      "JobModelCapabilities_DeviceJobProcessingCapabilities"
    );
    const info = xrxStringToDom(
      domDeviceConfigDeviceCapabilities
        .replace(/eipjobcap\:/g, "")
        .replace(/eipjobmodel\:/g, "")
    );
    // Color capability
    const versalinkColorSupportNode = info.documentElement.getElementsByTagName(
      "eipjobcap:ColorEffectsTypeSupported"
    );
    const altalinkColorSupportNode = info.documentElement.getElementsByTagName(
      "ColorEffectsTypeSupported"
    );
    const ColorSupportNode = versalinkColorSupportNode.length
      ? versalinkColorSupportNode
      : altalinkColorSupportNode;

    // Staple capability

    const versalinkStapleSupportNode = info.documentElement.getElementsByTagName(
      "eipjobcap:StapleFinishingsSupported"
    );
    const altalinkStapleSupportNode = info.documentElement.getElementsByTagName(
      "StapleFinishingsSupported"
    );
    const StapleSupportNode = versalinkStapleSupportNode.length
      ? versalinkStapleSupportNode
      : altalinkStapleSupportNode;

    let supportedValues = [];
    let stapleSupport = false;
    if (StapleSupportNode.length > 0) {
      const sfs = StapleSupportNode[0].getElementsByTagName("AllowedValue");
      for (let index = 0; index < sfs.length; index++) {
        supportedValues.push(
          StapleSupportNode[0].childNodes[index].textContent
        );
      }

      if (supportedValues.length > 1) {
        stapleSupport = true;
        this.stapleOptions = supportedValues;
      }
    }
    const result = {
      colorSupport: ColorSupportNode[0].textContent.includes("Color"),
      stapleSupport
    };

    this.deviceCapabilities = result;

    return result;
  }
  async getLanguageAsync() {
    if (this.language) return Promise.resolve(this.language);

    const languageId = await this.snmpService.getAsync(
      "1.3.6.1.2.1.43.5.1.1.10.1"
    );
    return this.snmpService.getAsync("1.3.6.1.2.1.43.7.1.1.2.1." + languageId);
  }
  getLanguage() {
    if (this.language) return this.language;

    const languageId = this.snmpService.get("1.3.6.1.2.1.43.5.1.1.10.1");
    return this.snmpService.get("1.3.6.1.2.1.43.7.1.1.2.1." + languageId);
  }
  //TODO: Add functions to get Language, EipVersion, A4 and DeviceIp. check latest informationHelper
  getDeviceConfigGetDeviceInformation() {
    return new Promise((resolve, reject) => {
      xrxDeviceConfigGetDeviceInformation(
        this.sessionDataService.deviceUrl,
        (env, response) => resolve(response),
        (error) => reject(error),
        30,
        true
      );
    });
  }

  getDeviceConfigGetDeviceCapabilities() {
    return new Promise((resolve, reject) => {
      xrxDeviceConfigGetDeviceCapabilities(
        this.sessionDataService.deviceUrl,
        (env, response) => resolve(response),
        (error) => reject(error),
        30,
        true
      );
    });
  }

  getA4(model) {
    //returns true if device supports A4

    //VersaLink: Paper Size Preference - Global: .1.3.6.1.4.1.253.8.53.13.2.1.6.1.296.3 (1 = inches, 2 = mm)
    //VersaLink: Units Measurement Setting: .1.3.6.1.4.1.253.8.53.13.2.1.6.1.296.2 (1 = inches, 2 = mm)
    //Looks like when ou change paper size pref it automatically changes units

    try {
      if (model.indexOf("Versa") > -1) {
        try {
          var versa = this.snmpService.get("1.3.6.1.4.1.253.8.53.13.2.1.6.1.296.2") //units 1 = inches, 2 = mm
          if (versa == 2)
            return true

          return false
        } catch (err) { }
      }

      if (model.indexOf("Alta") > -1) {
        try {
          var alta = this.snmpService.get(
            "1.3.6.1.4.1.253.8.74.6.2.1.9.2.101.239.2") //<![CDATA[name=iso-a4,color=white]]> or na-letter
          if (alta.indexOf("a4") > -1)
            return true
          else if (alta.indexOf("letter") > -1)
            return false
        } catch (err) { }
      }

      if (model.indexOf("Work") > -1) {
        try {
          var cki = this.snmpService.get(
            "1.3.6.1.4.1.253.8.74.6.2.1.9.2.101.239.2") //<![CDATA[name=iso-a4,color=white]]> or na-letter
          if (cki.indexOf("a4") > -1)
            return true
          else if (cki.indexOf("letter") > -1)
            return false
        } catch (err) { }
      }

    } catch (err) { }

    //unsure, user country
    var languageId = this.snmpService.get("1.3.6.1.2.1.43.5.1.1.10.1")
    var country = this.snmpService.get("1.3.6.1.2.1.43.7.1.1.3.1." + languageId)
    if (country != "US")
      return true


    return false
  }

  async getPaperPreferenceA4() {
    const deviceConfigDeviceInformation = await this.getDeviceConfigGetDeviceInformation()
    // SET SOME FATAL ERROR AFTER THIS
    if (!deviceConfigDeviceInformation)
      return Promise.reject()

    const domDeviceConfigDeviceInformation = xrxGetElementValue(xrxStringToDom(deviceConfigDeviceInformation), "Information")
    const info = xrxStringToDom(domDeviceConfigDeviceInformation)

    return this.getA4($(info).find("DeviceInformation > device > model").text())
  }
}
