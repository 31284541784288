/* --------------------------------------------------------------------------------------
errorTypes.const.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export const errorTypes = {
  servicesUnavailable: {
    titleKey: "EIP_SERVICES",
    keys: ["CONTACT_ADMINISTRATOR_TO_ENABLE"],
    exitApp: true,
  },
  sizeExceeded: {
    titleKey: "SCAN_EXCEEDS_FILE_SIZE_LIMIT",
    keys: ["ADJUST_SCAN_SETTINGS_DECREASE_SIZE_TRY_AGAIN"],
  },
  amountExceeded: {
    titleKey: "TOTAL_AMOUNT_EXCEEDS",
    keys: ["ADJUST_TOTAL_AMOUNT_DECREASE_TRY_AGAIN"],
  },
  scannedDocumentNotSaved: {
    titleKey: "SCANNED_DOCUMENT_NOT_SAVED",
    keys: ["PLEASE_TRY_AGAIN", "IF_PROBLEM_PERSISTS_CONTACT_XEROX"],
  },
  networkUnavailable: {
    titleKey: "APP_CANNOT_CONNECT_TO_NETWORK",
    keys: ["CHECK_DEVICE_NETWORK_CONNECTION"],
    exitApp: true,
  },
  cannotConnectWithIntacct: {
    titleKey: "CANNOT_CONNECT_WITH_INTACCT",
    keys: ["TRY_AGAIN_LATER", "IF_PROBLEM_PERSISTS_CONTACT_XEROX"],
    exitApp: true,
  },
  intacctUnavailable: {
    titleKey: "ERROR_UNAVAILABLE",
    keys: ["TRY_AGAIN_LATER", "IF_PROBLEM_PERSISTS_CONTACT_XEROX"],
    backToMain: true,
  },
  unauthorized: {
    titleKey: "TOKEN_HAS_EXPIRED",
    keys: ["LOGIN_AGAIN"],
    continueButton: true,
    logout: true,
  },
  filenameExists: {
    titleKey: "FILENAME_EXISTS",
    keys: ["ENTER_DIFFERENT_FILENAME"],
  },
  errorHasOccured: {
    titleKey: "ERROR_HAS_OCCURRED",
    keys: ["PLEASE_TRY_AGAIN", "IF_PROBLEM_PERSISTS_CONTACT_XEROX"],
    exitApp: true,
  },
  dateInvalid: {
    titleKey: "DUE_DATE_PREDATES_CURRENT_DATE",
    keys: ["DO_YOU_WANT_TO_CONTINUE"],
    continueButton: true,
  },
  allPagesUsed: {
    titleKey: "ALL_PAGES_USED",
    keys: ["PURCHASE_NEW_SUBSCRIPTION"],
    backToMain: true,
  },
  emailCannotBeSent: {
    titleKey: "EMAIL_CANNOT_BE_SENT",
    keys: ["PLEASE_TRY_AGAIN", "IF_PROBLEM_PERSISTS_CONTACT_XEROX"],
    backToMain: true,
  },
  documentCannotBeSentToSFTP: {
    titleKey: "DOCUMENT_CANNOT_BE_SENT",
    keys: ["CONTACT_ADMINISTRATOR_TO_CHECK_SETTINGS"],
    backToMain: true,
  },
  documentCannotBeSentToDestination: {
    titleKey: "DOCUMENT_COULD_NOT_BE_SENT_TO_DESTINATION",
    keys: ["CHECK_OUTPUT_TRAY_FOR_ERROR_SHEET"],
    backToMain: true,
  },
  documentCannotBeSentToFolder: {
    titleKey: "DOCUMENT_CANNOT_BE_SENT",
    keys: ["CHECK_DESTINATION_FOLDER_NAME"],
    backToMain: true,
  },
  transmissionFailed: {
    titleKey: "ERROR_DURING_TRANSMISSION",
    keys: ["PLEASE_TRY_AGAIN", "IF_PROBLEM_PERSISTS_CONTACT_XEROX"],
    backToMain: true,
  },
  jobManagementFailure: {
    titleKey: "JOB_MANAGEMENT_FAILURE",
    keys: ["PLEASE_TRY_AGAIN", "IF_PROBLEM_PERSISTS_CONTACT_XEROX"],
    backToMain: true,
  },
  processingFailure: {
    titleKey: "SOMETHING_WENT_WRONG",
    keys: ["PLEASE_TRY_AGAIN", "IF_PROBLEM_PERSISTS_CONTACT_XEROX"],
    backToMain: true,
  },
  appConfiguredIncorrectly: {
    titleKey: "APP_IS_NOT_CONFIGURED_CORRECTLY",
    keys: ["CONTACT_ADMINISTRATOR_TO_CHECK_APP_CONFIGURATION"],
    exitApp: true,
  },
  confirmUploadFailed: {
    titleKey: "ERROR_HAS_OCCURRED",
    keys: [
      "ERROR_SENDING_DOCUMENT_TO_SELECTED_WORKFLOW",
      "PLEASE_TRY_AGAIN",
      "IF_PROBLEM_PERSISTS_CONTACT_XEROX",
    ],
    exitApp: true,
  },
  fetchingScanPreviewFailed: {
    titleKey: "ERROR_HAS_OCCURRED",
    keys: [
      "ERROR_FETCHING_SCAN_PREVIEW",
      "PLEASE_TRY_AGAIN",
      "IF_PROBLEM_PERSISTS_CONTACT_XEROX",
    ],
    exitApp: true,
  },
};
