/* --------------------------------------------------------------------------------------
   xeroxEIPParameters.const.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const xeroxEIPParameters = {
  sides: [
    {
      key: "OneSided",
      translationKey: "ONE_SIDED",
      scope: ["scan", "print"],
      img: "./img/duplex_sided_one_48.svg",
    },
    {
      key: "TwoSided",
      translationKey: "TWO_SIDED_LONG",
      scope: ["scan", "print"],
      img: "./img/duplex_sided_two_48.svg",
    },
    {
      key: "SecondSideRotation",
      translationKey: "TWO_SIDED_SHORT",
      scope: ["scan", "print"],
      img: "./img/duplex_sided_rotate_48.svg",
    },
  ],
  resolutions: [
    { key: "72", resolution: "72x72", value: "72 dpi", img: "./img/resolution_72_48.svg", alta: true },
    { key: "100", resolution: "100x100", value: "100 dpi", img: "./img/resolution_100_48.svg", alta: true },
    { key: "150", resolution: "150x150", value: "150 dpi", img: "./img/resolution_150_48.svg", alta: true },
    { key: "200", resolution: "200x200", value: "200 dpi", img: "./img/resolution_200_48.svg" },
    { key: "300", resolution: "300x300", value: "300 dpi", img: "./img/resolution_300_48.svg" },
    { key: "400", resolution: "400x400", value: "400 dpi", img: "./img/resolution_400_48.svg" },
    { key: "600", resolution: "600x600", value: "600 dpi", img: "./img/resolution_600_48.svg" },
  ],
  colorModes: [
    {
      key: "Auto",
      translationKey: "AUTO_DETECT",
      img: "./img/output_color_auto_48.svg",
    },
    {
      key: "Fullcolor",
      translationKey: "COLOR",
      img: "./img/output_color_full_48.svg",
    },
    {
      key: "BlackAndWhite",
      translationKey: "BLACK_AND_WHITE",
      img: "./img/output_color_black_white_48.svg",
    },
    {
      key: "Grayscale",
      translationKey: "GRAYSCALE",
      img: "./img/output_color_grayscale_48.svg",
    },
  ],
  orientations: [
    {
      key: "PORTRAIT",
      translationKey: "PORTRAIT",
      img: "./img/orientation_portrait_48.svg",
    },
    {
      key: "LANDSCAPE",
      translationKey: "LANDSCAPE",
      img: "./img/orientation_landscape_48.svg",
    },
  ],
  sizes: [
    { key: "NA_5.5x8.5LEF", value: '5.5 x 8.5"', orientation: "P", size: [5.5, 8.5] },
    { key: "NA_5.5x8.5SEF", value: '5.5 x 8.5"', orientation: "L", size: [5.5, 8.5] },
    { key: "NA_8.5x11LEF", value: '8.5 x 11"', orientation: "P", size: [8.5, 11] },
    { key: "NA_8.5x11SEF", value: '8.5 x 11"', orientation: "L", size: [8.5, 11] },
    { key: "NA_8.5x13LEF", value: '8.5 x 13"', orientation: "P", size: [8.5, 13],},
    { key: "NA_8.5x14LEF", value: '8.5 x 14"', orientation: "P", size: [8.5, 14] },
    { key: "NA_11x17LEF", value: '11 x 17"', orientation: "P",  size: [8.5, 17], a3: true },
    { key: "ISO_A3LEF", value: "A3", orientation: "P", size: [11.7, 16.5], a3: true },
    { key: "ISO_A4LEF", value: "A4", orientation: "P", size: [8.3, 11.7] },
    { key: "ISO_A4SEF", value: "A4", orientation: "L", size: [8.3, 11.7] },
    { key: "ISO_A5LEF", value: "A5", orientation: "P", size: [5.8, 8.3] },
    { key: "ISO_A5SEF", value: "A5", orientation: "L", size: [5.8, 8.3] },
    { key: "ISO_B4LEF", value: "B4", orientation: "P", size: [9.8, 13.9] },
    { key: "ISO_B5LEF", value: "B5", orientation: "P", size: [6.9, 9.8] },
    { key: "ISO_B5SEF", value: "B5", orientation: "L", size: [6.9, 9.8] }
  ],
  types: [
    {
      key: "AUTO",
      translationKey: "AUTO",
      img: "./img/original_size_mode_auto_48.svg",
    },
    {
      key: "MIXED",
      translationKey: "TEXT_AND_PHOTO",
      img: "./img/original_type_text_photo_48.svg",
    },
    {
      key: "TEXT",
      translationKey: "TEXT",
      img: "./img/original_type_text_48.svg",
    },
    {
      key: "PHOTO",
      translationKey: "PHOTOGRAPH",
      img: "./img/original_type_photo_48.svg",
    },
  ],
  stapleOptions: [
    {
      key: "Staple",
      translationKey: "ON",
      img: "./img/orientation_portrait_48.png",
    },
    {
      key: "None",
      translationKey: "OFF",
      img: "./img/orientation_portrait_48.png",
    },
  ],
};
