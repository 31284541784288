/* --------------------------------------------------------------------------------------
   xdaCalendar.directive.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/
export class xdaCalendar {
  /* @ngInject */
constructor (moment) {
  this.restrict = 'E'
  this.scope =  {
      callback: '&',
      date: '=',
      click: '&',
      minDate: '<',
      maxDate: '<',
      dateFormat: '@',
      next: '&',
      prev: '&'
  },
  this.template = '<div id="xdaCalendar"></div>',
  this.moment = moment
 }
 link(scope, elem, attrs) {
   $('#xdaCalendar').pignoseCalendar({
       date: scope.date,
       minDate: scope.minDate,
       maxDate: scope.maxDate,
       click: scope.click,
       select: (date) => this.selected(date, scope),
       next: scope.next,
       prev: scope.prev
   });
 }
 selected(date, scope) {
   scope.date = date[0]
 }
 static directiveFactory(moment) {
   xdaCalendar.instance = new xdaCalendar(moment)
   return xdaCalendar.instance
 }
}
